
import { Component, Vue } from 'vue-property-decorator'
import { XHeader, XFooter } from '@/layout/components'
import { saveTouristInfo, TouristInfo } from '@/api/users'
import { IOption } from '@/utils/interface'
import type { ElForm } from 'element-ui/types/form'

@Component({
  name: 'ContactUs',
  components: { XHeader, XFooter },
})
export default class extends Vue {
  /** 数据 */
  form = {} as TouristInfo
  roleList: IOption[] = [
    { text: '企业方招聘HR', value: '企业方招聘HR' },
    { text: '企业方用人部门', value: '企业方用人部门' },
    { text: '求职者', value: '求职者' },
  ]

  rules = {
    name: [{ required: true, message: '请输入您的项目', trigger: 'change' }],
    company: [{ required: true, message: '请输入公司名称', trigger: 'change' }],
    phone: [{ required: true, message: '请输入联系电话', trigger: 'change' }],
    identity: [{ required: true, message: '请选择一个身份类型', trigger: 'change' }],
  }

  onSubmit() {
    ;(this.$refs.form as ElForm).validate(valid => {
      // console.log('valid', valid, this.form)
      if (valid) {
        saveTouristInfo(this.form).then(() => {
          this.$message({ message: '提交成功', type: 'success' })
          this.$router.push({ name: 'home' })
        })
      } else {
        console.log('error submit!!')
      }
    })
  }
}
